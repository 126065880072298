<template>
  <div class="app">
    <el-carousel
      class="home-swiper"
      :interval="100000"
      arrow="hover"
      trigger="click"
      :height="swiperHeight + 'px'"
      :loop="true"
    >
      <el-carousel-item class="swiper-item" v-for="(swiper, index) in banner" :key="index">
        <img class="swiper-item-img" :src="swiper.img">
        <!-- <img src="https://www.caikutong.com/storage/uploads/images/2019/04/26/c172d7751b2137ad1ecb9e3c67490c6e.jpg" alt=""> -->
      </el-carousel-item>
    </el-carousel>
    <div class="product clearfix">
      <div class="common-title">
        <p>产品中心</p>
        <span></span>
      </div>
      <div class="common-content clearfix">
        <el-row :gutter="10">
          <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
            <div class="grid-content bg-purple">
              <svg class="icon myIconStyle" aria-hidden="true">
                <use xlink:href="#icon-ziyuan"></use>
              </svg>
              <div class="item-content">
                <h3>劳务从业人员培训</h3>
                <p>HR服务培训、劳资法规培训、劳务高管训练</p>
              </div>
            </div>
          </el-col>
          <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
            <div class="grid-content bg-purple-light">
              <svg class="icon myIconStyle" aria-hidden="true">
                <use xlink:href="#icon-ziyuan1"></use>
              </svg>
              <div class="item-content">
                <h3>劳务公司管理咨询</h3>
                <p>财税咨询、法务咨询、管理咨询</p>
              </div>
            </div>
          </el-col>
          <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
            <div class="grid-content bg-purple">
              <svg class="icon myIconStyle" aria-hidden="true">
                <use xlink:href="#icon-ziyuan2"></use>
              </svg>
              <div class="item-content">
                <h3>劳务公司联盟管理</h3>
                <p>渠道合作、专业分包、品牌加盟</p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="advantage">
      <div class="common-title">
        <p>我们的优势</p>
        <span></span>
      </div>
      <div class="common-content clearfix">
        <el-row :gutter="10">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content bg-purple">
              <svg class="icon myIconStyle" aria-hidden="true">
                <use xlink:href="#icon-churujingx"></use>
              </svg>
              <div class="item-content">
                <h4>我们的优势之一</h4>
                <p>时间考验：10多年的历史沉淀，造就了业界知名品牌。无论外部环境如何变化，始终坚守领域的研究与实践。</p>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content bg-purple-light">
              <svg class="icon myIconStyle" aria-hidden="true">
                <use xlink:href="#icon-gongzuotai"></use>
              </svg>
              <div class="item-content">
                <h4>我们的优势之二</h4>
                <p>内容领域：专注于关键领域研究与实践，又适用于大多数行业与企业形态。为中小企业主解决最关键最困惑的问题，让中小企业主专注于其自身擅长的业务管理。</p>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content bg-purple">
              <svg class="icon myIconStyle" aria-hidden="true">
                <use xlink:href="#icon-zuzhijigou"></use>
              </svg>
              <div class="item-content">
                <h4>我们的优势之三</h4>
                <p>团队优势：在顶层设计、人才激励、节税工程方面我面搭建了国内一流的专家团队。</p>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content bg-purple-light">
              <svg class="icon myIconStyle" aria-hidden="true">
                <use xlink:href="#icon-zongheguanli"></use>
              </svg>
              <div class="item-content">
                <h4>我们的优势之四</h4>
                <p>系统优势：系统性地解决中小企业主的关键问题，让中小企业主彻底解放，持久解放，免于头痛医头，脚痛医脚。</p>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content bg-purple-light">
              <svg class="icon myIconStyle" aria-hidden="true">
                <use xlink:href="#icon-yingyongzhongxin"></use>
              </svg>
              <div class="item-content">
                <h4>我们的优势之五</h4>
                <p>网点服务：全国大多数省会城市皆有我们的服务网点。</p>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content bg-purple-light">
              <svg class="icon myIconStyle" aria-hidden="true">
                <use xlink:href="#icon-zonghechaxun"></use>
              </svg>
              <div class="item-content">
                <h4>我们的优势之六</h4>
                <p>生态对接：在多行业，多领域资源对接，在基础建设的优势上为中小企业对接资源。</p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 新闻资讯 -->
    <div class="news">
      <div class="common-title">
        <p>新闻资讯</p>
        <span></span>
      </div>
      <div class="new-bg">
        <div class="arrow-container">
          <!-- 箭头 left -->
          <span class="arrow-left arrow" @click="previousPage">
              <svg class="icon myIconStyle" aria-hidden="true">
                <use xlink:href="#icon-jiantou"></use>
              </svg>
            </span>
          <div class="common-content clearfix">
            <!-- 新闻资讯内容 -->
            <ul :style="'left: ' + left + 'px;width: ' + 320 * news.length + 'px'">
              <li v-for="(item, index) in news" :key="index" :style="'left: ' + index * 320 + 'px;'">
                <div class="new-content-item-img">
                  <img :src="item.cover" alt>
                </div>
                <div>
                  <h4>{{item.title}}</h4>
                  <p>{{item.digest}}</p>
                  <!-- type: 1 站内新闻 -->
                  <router-link
                    :to="{name: 'Newsdetail', params: {id: item.id}}"
                    target="_blank"
                    v-if="item.type === 1"
                  >阅读全文</router-link>
                  <!-- type: 2 站外新闻 -->
                  <a :href="item.link" target="_blank" v-if="item.type === 2">阅读全文</a>
                </div>
              </li>
            </ul>
          </div>
          <!-- 箭头 right -->
          <span class="arrow-right arrow" @click="nextPage">
              <svg class="icon myIconStyle" aria-hidden="true">
                <use xlink:href="#icon-jiantou"></use>
              </svg>
            </span>
        </div>
      </div>
    </div>

    <div class="customer clearfix">
      <div class="common-title">
        <p>我们的客户</p>
        <span></span>
      </div>
      <div class="common-content clearfix">
        <el-row :gutter="10">
          <el-col :xs="12" :sm="3" :md="3" :lg="3" :xl="3" v-for="(ele,ind) in parner" :key="ind">
            <a :href="ele.link" target="_blank">
              <img :src="ele.img" alt="" @error="testFun" v-if="isShow">
              <!--<img src="../assets/images/shouye_02.jpg" alt="">-->
              <!-- <img
                src="https://www.caikutong.com/storage/uploads/uploads/images/2019/03/15/partner-1.jpg"
                alt
              >-->
            </a>
          </el-col>
        </el-row>
        <!-- <ul class="clearfix">
          <li v-for="(ele,ind) in parner" :key="ind">
            <a :href="ele.link" target="_blank">
              <img :src="ele.img" alt>
            </a>
          </li>
        </ul>-->
      </div>
    </div>
  </div>
</template>
<script>
// import { setTimeout } from 'timers'
export default {
  data () {
    return {
      banner: [],
      product: [],
      parner: [],
      // 新闻列表
      news: [],
      left: 0,
      // 新闻当前页面
      page: 1,
      // 新闻最后一页
      lastPage: 1,
      swiperHeight: '',
      clientWidth: 0,
      isShow: true
    }
  },
  methods: {
    testFun () {
      this.isShow = false
    },
    // 新闻列表切换下一页
    next (num) {
      if (Math.abs(this.left) + num < 320 * this.news.length) {
        this.left = this.left - num
      } else {
        if (this.lastPage > this.page) {
          this.getNews(`/api/web/news?page=${this.page + 1}`)
        }
      }
    },
    nextPage () {
      if (this.clientWidth > 1160) {
        this.next(960)
      } else if (this.clientWidth > 700) {
        this.next(640)
      } else {
        this.next(320)
      }
    },
    // 上一页
    previousPage () {
      if (this.left < 0) {
        this.left = this.left + 320
      }
    },
    // 获取轮播列表
    getSwipers () {
      this.$store.dispatch('getSwipers').then(res => {
        this.banner = res
        setTimeout(() => {
          this.swiperHeight = document
            .querySelectorAll('.swiper-item-img')[0]
            .height.toString()
        }, 500)
      })
    },
    // 获取首页产品中心数据
    getProducts () {},
    // 获取首页新闻列表数据
    getNews (url) {
      this.$store.dispatch('getNews', url).then(res => {
        if (res.data.length > 0) {
          res.data.forEach(item => {
            this.news.push(item)
          })
        }
        // 处理页面
        this.page = res.current_page
        // 最后一页
        this.lastPage = res.last_page
      })
    },
    // 获取合作伙伴数据
    getPartner () {
      this.$store.dispatch('getPartner').then(res => {
        this.parner = res
      })
    }
  },
  mounted () {
    // 获取轮播列表
    this.getSwipers()
    // 获取新闻列表
    this.getNews()
    // 获取合作伙伴
    this.getPartner()
    // 监听浏览器窗口的变化
    this.clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth
    window.addEventListener('resize', () => {
      if (document.querySelectorAll('.swiper-item-img')[0]) {
        this.swiperHeight = document
          .querySelectorAll('.swiper-item-img')[0]
          .height.toString()
      }
      this.clientWidth =
        document.documentElement.clientWidth || document.body.clientWidth

      // this.AdaptSwipers(this.clientWidth);
    })
  }
}
</script>
<style scoped lang="less">
// index 公共
// .common-content {
//   width: 1200px;
//   margin: 0 auto;
//   text-align: center;
.icon {
  width: 70px;
  height: 80px;
}
/*img[src=""],img:not([src]) {opacity: 0}*/
// }
//banner样式
.home-swiper {
  width: 100%;
  .swiper-item {
    width: 100%;
    img {
      width: 100%;
    }
  }
}
// 加入财库通优势
.advantage {
  .grid-content {
    display: flex;
    min-height: 130px;
    .item-content {
      flex: 1;
      text-align: left;
      margin-left: 20px;
      h4{
        margin: 0;
      }
      p{
        margin: 0
      }
    }
  }
}
// 新闻资讯
.news {
  .new-bg {
    height: 600px;
    background: url("../assets/images/shouye_02.jpg") no-repeat;
    .arrow-container{
      max-width: 1200px;
      position: relative;
      margin: 0 auto;
      .arrow {
        position: absolute;
        top: 45%;
        z-index: 99;
        &.arrow-left {
          transform: rotate(180deg);
          left: 4rem;
        }
        &.arrow-right {
          right: 4rem;
        }
      }
      .common-content {
        width: 960px;
        height: 450px;
        margin: 20px auto 0;
        overflow: hidden;
        position: relative;
        padding-top: 70px;
        ul {
          display: flex;
          height: 100%;
          position: absolute;
          transition: left 1.1s linear;
          li {
            width: 270px;
            margin: 0 25px;
            height: 450px;
            border-radius: 10px;
            box-shadow: 2px 5px 20px @global-gray;
            background: @global-white;
            position: absolute;
            top: 0;
            .new-content-item-img {
              height: 220px;
              width: 100%;
              margin-bottom: 15px;
              border-radius:  @border-radius;
              img {
                display: inline-block;
                height: 100%;
                width: 100%;
                border-radius: @border-radius;
              }
            }
            h4 {
              letter-spacing: 1px;
              text-align: center;
              margin-bottom: 15px;
              padding: 0 20px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            p {
              text-indent: 2em;
              margin: 0 20px 35px 20px;
              line-height: 25px;
              letter-spacing: 1px;
              // font-size: 15px;
              color: #979a9c;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              overflow: hidden;
              -webkit-box-orient: vertical;
            }
            a {
              position: absolute;
              left: 50%;
              bottom: 25px;
              margin-left: -60px;
              display: block;
              width: 110px;
              height: 38px;
              line-height: 38px;
              border-radius: 8px;
              background: #efcc2b;
              // font-size: 15px;
              letter-spacing: 1px;
              text-align: center;

            }
          }
        }
      }
    }
  }
}
// 合作伙伴
.customer {
  .el-col {
    height: 100px;
    a {
      box-sizing: border-box;
      border: 1px solid @global-gray;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        -webkit-appearance: none;
        /*opacity: 1;*/
        border: none;
      }
    }
  }
}

@media screen and (max-width: 1160px) and (min-width: 700px) {
  // 公共
  // .common-content {
  //   width: auto;
  // }
  // 新闻资讯
  .news {
    .common-content {
      width: 640px !important;
    }
  }
}
@media screen and (max-width: 1000px) and (min-width: 771px) {
  .news {
    .new-bg {
      .arrow-container{
        .arrow {
          &.arrow-left {
            left: 1rem;
          }
          &.arrow-right {
            right: 1rem;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 770px) and (min-width: 700px) {
  // 新闻资讯
  .news {
    .new-bg {
      .arrow-container{
        .arrow {
          &.arrow-left {
            left: 0rem;
          }
          &.arrow-right {
            right: 0rem;
          }
        }
      }
    }
  }
  // 合作伙伴
  .customer {
    .el-col {
      margin-bottom: 10px;
    }
    a {
      height: auto !important;
      img {
        border: none;
      }
    }
  }
}
@media screen and (max-width: 700px) {
  // 公共
  .common-content {
    // width: auto;
    .icon {
      width: 60px;
      height: 80px;
    }
    .item-content {
      p {
        font-size: 14px;
      }
    }
  }
  // 新闻资讯
  .news {
    .new-bg {
      .arrow-container{
        .arrow {
          &.arrow-left {
            left: 2rem;
          }
          &.arrow-right {
            right: 2rem;
          }
        }
      }
    }
    .common-content {
      width: 320px !important;
    }
  }
  // 合作伙伴
  .customer {
    .el-col {
      margin-bottom: 10px;
    }
    a {
      height: 100%;
      img {
        border: none;
      }
    }
  }
}
@media screen and (max-width: 460px) {
  // 新闻资讯
  .news {
    .new-bg {
      .arrow-container{
        .arrow {
          &.arrow-left {
            left: -.6rem;
          }
          &.arrow-right {
            right: -.6rem;
          }
        }
      }
    }
  }
}
</style>
